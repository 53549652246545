import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazyImage'

import { ImageDataLike } from 'gatsby-plugin-image'

type SectionSingle = {
  img: ImageDataLike
  alt: string
  heading: string
  description: string
}

type Props = {
  introText: string
  sections: SectionSingle[]
  patentsObtained: string[]
  patentsApplications: string[]
}

const Wrapper = styled.div`
  padding-bottom: 40px;

  ${({ theme }) => theme.media.lg.min} {
    padding-bottom: 80px;
  }
`

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 0 40 0;

  ${({ theme }) => theme.media.lg.min} {
    gap: 20px;
  }
`

const ImageWrapper = styled.div`
  position: relative;

  ${({ theme }) => theme.media.lg.min} {
    width: 30%;
    min-height: 250px;

    .section-image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

const ContentWrapper = styled.div`
  ${({ theme }) => theme.media.lg.min} {
    width: 70%;
  }
`

const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    padding-top: 20px;
    gap: 50px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 10px;
      border-radius: 3px;
      background-color: ${({ theme }) => theme.colors.primary};
    }

    ${ImageWrapper} {
      .gatsby-image-wrapper {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
    }

    &:nth-of-type(odd) {
      ${ImageWrapper} {
        order: 1;

        .gatsby-image-wrapper {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }

    &:nth-of-type(even) {
      padding-top: 30px;
    }

    &:last-of-type {
      ${ImageWrapper}, ${ContentWrapper} {
        width: 50%;
      }
    }
  }
`

const StyledIntroText = styled(Text)`
  padding: 0 0 20px 0;
`

const PatentsSubSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 10px;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const TextWithBullet = styled(Text)`
  display: flex;
  align-items: center;

  :before {
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    background-color: ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    margin-right: 8px;
  }
`

const Sections: React.FC<Props> = ({
  introText,
  sections,
  patentsObtained,
  patentsApplications,
}) => {
  return (
    <Wrapper>
      <Container>
        <StyledIntroText
          size={18}
          dangerouslySetInnerHTML={{ __html: introText }}
        />
        <SectionsWrapper>
          {sections.map(({ img, alt, heading, description }) => (
            <Section>
              <ImageWrapper>
                <LazyImage className="section-image" src={img} alt={alt} />
              </ImageWrapper>
              <ContentWrapper>
                <Heading
                  as="h2"
                  size={32}
                  margin="20px"
                  themecolor="primary"
                  weight={600}
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
                <Text
                  size={16}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                {heading === 'Patenty' && (
                  <PatentsSubSection>
                    <div>
                      <Text size={16} weight={600}>
                        Uzyskane patenty:
                      </Text>
                      {patentsObtained.map((el) => (
                        <TextWithBullet size={16}>{el}</TextWithBullet>
                      ))}
                    </div>
                    <div>
                      <Text size={16} weight={600}>
                        Zgłoszenia patentowe:
                      </Text>
                      {patentsApplications.map((el) => (
                        <TextWithBullet size={16}>{el}</TextWithBullet>
                      ))}
                    </div>
                  </PatentsSubSection>
                )}
              </ContentWrapper>
            </Section>
          ))}
        </SectionsWrapper>
      </Container>
    </Wrapper>
  )
}

export default Sections
