import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import Sections from 'components/layout/Sections/Pages/GatigoLab/Sections'

import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Gatigo lab',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Gatigo lab',
    },
  ],
}

const GatigoLabPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.gatigoLab
  const PAGE_SEO = data?.wpPage?.seo

  const SECTIONS = React.useMemo(
    () => [
      {
        img: PAGE?.gatigoLabIngeniurImg?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: 'section',
        heading: PAGE?.gatigoLabIngeniurTitle,
        description: PAGE?.gatigoLabIngeniurDescription,
      },
      {
        img: PAGE?.gatigoLabDesignImg?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: 'section',
        heading: PAGE?.gatigoLabDesignTitle,
        description: PAGE?.gatigoLabDesignDescription,
      },
      {
        img: PAGE?.gatigoLabDurabilityTestImg?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: 'section',
        heading: PAGE?.gatigoLabDurabilityTestTitle,
        description: PAGE?.gatigoLabDurabilityDescription,
      },
      {
        img: PAGE?.gatigoLabPatentImg?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: 'section',
        heading: PAGE?.gatigoLabPatentTitle,
        description: PAGE?.gatigoLabPatentDescription,
      },
      {
        img: PAGE?.gatigoLabModernProductionImg?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: 'section',
        heading: PAGE?.gatigoLabModernProductionTitle,
        description: PAGE?.gatigoLabModernProductionDescription,
      },
    ],
    []
  )

  const PATENTS_OBTAINED = React.useMemo(
    () =>
      PAGE?.gatigoLabPatentObtained.map(
        (item) => item.gatigoLabPatentObtainedSingle
      ),
    []
  )
  const PATENTS_APPLICATIONS = React.useMemo(
    () =>
      PAGE?.gatigoLabPatentApplications.map(
        (item) => item.gatigoLabPatentApplicationsSingle
      ),
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.gatigoLabImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.gatigoLabImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        headingMobile={PAGE?.gatigoLabTitle}
        headingDesktop={PAGE?.gatigoLabTitle}
        breadcrumbs={breadcrumbs}
      />
      <Sections
        introText={PAGE?.gatigoLabDescription}
        sections={SECTIONS}
        patentsObtained={PATENTS_OBTAINED}
        patentsApplications={PATENTS_APPLICATIONS}
      />
    </Layout>
  )
}

export default GatigoLabPage

export const query = graphql`
  query GatigoLabPage {
    wpPage(slug: { regex: "/gatigo-lab/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      id
      gatigoLab {
        fieldGroupName
        gatigoLabDescription
        gatigoLabDesignDescription
        gatigoLabDesignTitle
        gatigoLabDurabilityDescription
        gatigoLabDurabilityTestTitle
        gatigoLabIngeniurDescription
        gatigoLabIngeniurTitle
        gatigoLabModernProductionDescription
        gatigoLabModernProductionTitle
        gatigoLabPatentDescription
        gatigoLabPatentTitle
        gatigoLabTitle
        gatigoLabDesignImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        gatigoLabDurabilityTestImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        gatigoLabImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        gatigoLabIngeniurImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        gatigoLabModernProductionImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        gatigoLabPatentImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        gatigoLabPatentApplications {
          gatigoLabPatentApplicationsSingle
        }
        gatigoLabPatentObtained {
          gatigoLabPatentObtainedSingle
        }
      }
    }
  }
`
